import Rebase from "re-base";
import firebase from "firebase";

const APIKEY = "AIzaSyAG-RMHlZ-WIwloWiV782K0ENwOTrKa3X8";
const AUTHDOMIN = "catch-of-the-day-williamshelly.firebaseapp.com";
const DATABASEURL =
  "https://catch-of-the-day-williamshelly-default-rtdb.firebaseio.com";

const firebaseApp = firebase.initializeApp({
  apiKey: APIKEY,
  authDomain: AUTHDOMIN,
  databaseURL: DATABASEURL,
});

const base = Rebase.createClass(firebaseApp.database());

//this is a named export
export { firebaseApp };

// this is a default export
export default base;
